import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { contactUsSection } from '../../../../utils/contactUsData';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
    background: '#68030F',
    fontFamily: 'BloggerSans',
    color: 'white',
    height: '100vh',
  },
  container: {
    backgroundImage: `url(${contactUsSection.pageInfo.bgMobile})`,
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
  },
  sectionContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '80px 0px 20px',
  },
  menu: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  menuItem: {
    fontFamily: 'BloggerSans',
    color: 'white',
    textDecoration: 'none',
    letterSpacing: '1.3px',
    fontSize: '18px',
    margin: '0 20px 25px 20px',
    display: 'block',
    '&:last-child': {
      marginBottom: '0px',
    },
    '&:hover': {
      fontFamily: 'BloggerSansBold',
    },
  },
  secondColumn: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  logo: {
    width: '40vh',
  },
  copyright: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    color: 'white',
    fontSize: '14px',
    marginTop: '30px',
  },
  joinCommunity: {
    fontFamily: 'BlockBerthold',
    fontSize: '28px',
    letterSpacing: '1.4px',
    fontWeight: 400,
  },
  imageMedia: {
    width: '60px',
    height: '60px',
    padding: '0 25px 0 25px',
  },
}));

interface Props {
  handlePageChange: any;
  currentPage: number;
}
const LandingPageContactUsMobile = ({ handlePageChange, currentPage }: Props) => {
  const classes = useStyles();
  const [isHoverImage, setIsHoverImage] = useState(-1);
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <Grid className={classes.sectionContent}>
            <Link
              activeClass='active'
              to='header'
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={() => handlePageChange(0)}
              onFocus={() => void 0}
              aria-hidden='true'
              className={classes.logoContainer}>
              <img src={contactUsSection.pageInfo.logo} alt='contactUsLogoSugarKingdom' className={classes.logo} />
            </Link>
            <Grid container direction='column' className={classes.secondColumn}>
              <Grid item className={`${classes.menu} w-p-100`}>
                {contactUsSection.pages.map((page) => (
                  <Link
                    key={page.name}
                    style={{ marginBottom: '5px' }}
                    activeClass='active'
                    to={page.toPageMobile}
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    onClick={() => handlePageChange(page.pageMobileId)}
                    onFocus={() => void 0}
                    aria-hidden='true'>
                    <span className={classes.menuItem}>{page.name}</span>
                  </Link>
                ))}
              </Grid>
              <Grid item className={'w-p-100'}>
                <p className={classes.joinCommunity}>Join our Community</p>
              </Grid>
              <Grid item>
                {contactUsSection.socialIcons.map((icon) => (
                  <a key={icon.name} href={icon.url} target={'_blank'} rel='noreferrer'>
                    <img
                      onMouseOver={(e) => setIsHoverImage(icon.setImg)}
                      onMouseLeave={(e) => setIsHoverImage(-1)}
                      aria-hidden='true'
                      onFocus={() => void 0}
                      className={classes.imageMedia}
                      src={isHoverImage === icon.setImg ? icon.imgHover : icon.img}
                      alt={`contact us ${icon.name}`}
                    />
                  </a>
                ))}
              </Grid>
              <Grid item className={'w-p-100'}>
                <p className={classes.copyright}>©{currentYear} Sugar Kingdom Osyssey - All Rights Reserved.</p>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default LandingPageContactUsMobile;
