/* eslint-disable jsx-a11y/media-has-caption */
import { makeStyles, Theme, Grid } from '@material-ui/core';
import React, { useRef } from 'react';
import { sectionsGameView, gameViewImages } from '../../../../utils/sectionGameViewData';
import { useScroll } from '../../../../hooks/useScroll';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: 'white',
  },
  mainContainer: {
    backgroundImage: `url(${gameViewImages.background})`,
    backgroundPositionY: 'bottom',
    backgroundSize: 'cover',
    position: 'relative',
    display: 'flex',
    justifySelf: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingTop: '100px',
    '&>iframe': {
      //aspectRatio: '60/55',
      width: '55%',
      height: '60%',
    },
    zIndex: 1,
    [theme.breakpoints.only('md')]: {},
    [theme.breakpoints.only('xl')]: {},
  },
  videoVimeo: {
    borderImage: `url(${gameViewImages.border})`,
    borderImageSlice: '35',
    borderWidth: '35px',
    borderStyle: 'solid',
    //animation: 'fadeoutfadeinTeam 1s linear',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.up('xl')]: {},
  },
  imageChar1: {
    width: '15vw',
    display: 'block',
    position: 'absolute',
    bottom: '80px',
    left: '7vw',
    indexZ: 10,
    animation: 'slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
    [theme.breakpoints.down('md')]: {},
  },
  imageChar2: {
    width: '18vw',
    display: 'block',
    position: 'absolute',
    bottom: '80px',
    right: '5vw',
    indexZ: 10,
    animation: 'slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
    [theme.breakpoints.down('md')]: {},
  },
  label: {
    width: '300px',
    display: 'block',
    position: 'absolute',
    top: '18.5vh',
    margin: 'auto',
    indexZ: 10,
    animation: 'slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
    [theme.breakpoints.only('md')]: {
      top: '16.5vh',
      width: '400px',
    },
    [theme.breakpoints.only('lg')]: {
      top: '15.5vh',
      width: '450px',
    },
    [theme.breakpoints.only('xl')]: {
      top: '14.5vh',
      width: '500px',
    },
  },
}));

const LandingPageGameView = () => {
  const classes = useStyles();
  const videoRef1 = useRef(null);
  const refSection = useRef(null);
  const { scrollPosition } = useScroll();

  return (
    <div className={classes.root} id='GameView'>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='space-between'
        className={classes.mainContainer}
        ref={refSection}>
        <Grid direction='row' container alignItems='center' justifyContent='center' className={classes.gridContainer}>
          <iframe
            width='100%'
            height='100%'
            ref={videoRef1}
            className={classes.videoVimeo}
            src={sectionsGameView[0].src}
            allow='fullscreen; accelerometer; autoplay; encrypted-media; picture-in-picture'
            allowFullScreen
            title='video'></iframe>
          {scrollPosition === 2 && (
            <>
              <img src={gameViewImages.gameView} alt='game view' className={`${classes.label}`} />
              <img src={gameViewImages.char1} alt='imageChar1' className={`${classes.imageChar1}`} />
              <img src={gameViewImages.char2} alt='imageChar2' className={`${classes.imageChar2}`} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPageGameView;
