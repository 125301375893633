import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CarouselDesktopNavigator from './CarouselDesktopNavigator';
import { sectionsGoals } from '../../../../utils/sectionKingdomSugarData';
import { useScroll } from '../../../../hooks/useScroll';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: 'white',
  },
  background: {
    margin: '0 auto',
    backgroundImage: `url(${sectionsGoals.pageInfo.bg})`,
    backgroundSize: 'cover',
    height: '100vh',
    zIndex: -2,
  },
  animationRight: {
    animation: 'lightSpeedInRight 2s ease-out',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    listStyle: 'none',
    height: '100%',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      marginTop: '18vh',
    },
  },
  contentText: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    textAlign: 'center',
    indexZ: 2,
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '26px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '28px',
    },
  },
  imageHeader: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '146px',
    [theme.breakpoints.down('md')]: {
      width: '12vh',
    },
    [theme.breakpoints.up('md')]: {
      width: '18vh',
    },
    [theme.breakpoints.up('lg')]: {
      width: '20vh',
    },
  },
  imageChar1: {
    width: '45vh',
    display: 'block',
    position: 'absolute',
    bottom: '120px',
    right: '10vw',
    indexZ: 10,
    animation: 'slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  imageChar2: {
    width: '45vh',
    display: 'block',
    position: 'absolute',
    bottom: '120px',
    left: '10vw',
    indexZ: 10,
    animation: 'slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  imageChar3: {
    width: '45vh',
    display: 'block',
    position: 'absolute',
    bottom: '120px',
    right: '10vw',
    indexZ: 10,
    animation: 'slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  bodyContainer: {
    position: 'absolute',
    height: '100vh',
    indexZ: -1,
  },
  contentContainer: {
    maxWidth: '1440px',
    height: '70vh',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    [theme.breakpoints.down('xl')]: {
      margin: '0px 10px 0px 10px',
    },
  },
  rightContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '60vw',
    minHeight: '75vh',
    marginTop: '5vh',
    boxSizing: 'border-box',
    width: '100%',
    padding: '20vh 10vw 0px',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.up('lg')]: {},
  },
  rightContent: {
    right: '10vw',
    [theme.breakpoints.down('md')]: {
      right: 'initial',
      maxWidth: '100vw',
      minHeight: '75vh',
      padding: '0px 10vw',
    },
  },
  leftContent: {
    left: '10vw',
    [theme.breakpoints.down('md')]: {
      left: 'initial',
      maxWidth: '90vw',
      minHeight: '75vh',
      padding: '0px 10vw',
    },
  },
  leftContentLoop: {
    left: '10vw',
    [theme.breakpoints.down('md')]: {
      left: 'initial',
      maxWidth: '90vw',
      minHeight: '75vh',
      padding: '0px 10vw',
    },
  },
  animationContainerRight: {
    animation: 'slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
  },
  animationContainerLeft: {
    animation: 'slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
  },
  animationContainerLeftLoop: {
    animation: 'slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
  },
  firstAnimation: {
    animation: 'fadeInDown 2s both 1',
  },
  imageAnimation: {
    animation: 'fadeInLeft 2s both 1',
  },
}));

const LandingSugarKingdomOdyssey = () => {
  const classes = useStyles();

  const [activeIndex, setActiveIndex] = useState(0);
  const { scrollPosition } = useScroll();

  useEffect(() => {
    if (scrollPosition !== 1) {
      setActiveIndex(0);
    }
  }, [scrollPosition]);

  const updateIndex = (newIndex: number) => {
    if (newIndex >= 0 && newIndex < sectionsGoals.sections.length) {
      setActiveIndex(newIndex);
    } else if (newIndex >= sectionsGoals.sections.length) {
      setActiveIndex(0);
    }
  };

  return (
    <>
      <div className={classes.root} id='sugarKingdomOdyssey'>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          className={classes.background}>
          {scrollPosition === 1 && (
            <>
              <Grid
                direction='row'
                container
                alignItems='center'
                justifyContent='center'
                className={`${classes.bodyContainer}`}>
                <Grid
                  direction='row'
                  container
                  alignItems='center'
                  justifyContent='center'
                  className={classes.contentContainer}>
                  {activeIndex === 0 && (
                    <Grid
                      className={`${classes.rightContainer} ${classes.leftContent} ${classes.animationContainerLeft}`}
                      style={{
                        backgroundImage: `url(${sectionsGoals.sections[activeIndex].imageHeader})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}>
                      <Grid container alignItems='center' justifyContent='center' className={classes.textContainer}>
                        {sectionsGoals.sections[activeIndex].text.map((text, i) => (
                          <li className={classes.contentText} key={`goal${i}`}>
                            {text}
                          </li>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  {activeIndex === 1 && (
                    <Grid
                      className={`${classes.rightContainer} ${classes.rightContent} ${classes.animationContainerRight}`}
                      style={{
                        backgroundImage: `url(${sectionsGoals.sections[activeIndex].imageHeader})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}>
                      <Grid container alignItems='center' justifyContent='center' className={classes.textContainer}>
                        {sectionsGoals.sections[activeIndex].text.map((text, i) => (
                          <li className={classes.contentText} key={`goal${i}`}>
                            {text}
                          </li>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  {activeIndex === 2 && (
                    <Grid
                      className={`${classes.rightContainer} ${classes.leftContentLoop} ${classes.animationContainerLeftLoop}`}
                      style={{
                        backgroundImage: `url(${sectionsGoals.sections[activeIndex].imageHeader})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}>
                      <Grid container alignItems='center' justifyContent='center' className={classes.textContainer}>
                        {sectionsGoals.sections[activeIndex].text.map((text, i) => (
                          <li className={classes.contentText} key={`goal${i}`}>
                            {text}
                          </li>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <div style={{ zIndex: '2' }}>
                {activeIndex === 0 && (
                  <img
                    src={sectionsGoals.sections[activeIndex].slider1}
                    alt='imageChar1'
                    className={`${classes.imageChar1}`}
                  />
                )}
                {activeIndex === 1 && (
                  <img
                    src={sectionsGoals.sections[activeIndex].slider2}
                    alt='imagechar2'
                    className={`${classes.imageChar2}`}
                  />
                )}
                {activeIndex === 2 && (
                  <img
                    src={sectionsGoals.sections[activeIndex].slider3}
                    alt='imagechar3'
                    className={`${classes.imageChar3}`}
                  />
                )}
              </div>
            </>
          )}
          <CarouselDesktopNavigator data={sectionsGoals.sections} activeIndex={activeIndex} updateIndex={updateIndex} />
        </Grid>
      </div>
    </>
  );
};

export default LandingSugarKingdomOdyssey;
