import logo from '../assets/images/menu/logoBar.svg';
import menuBackground from '../assets/images/menu/menuBackground.svg';
import miniBackground from '../assets/images/menu/menuMiniBackground.svg';
import miniBackgroundMobile from '../assets/images/menu/menuMiniBgMobile.svg';
import playNowButton from '../assets/images/menu/playNow.svg';
import playNowButtonHover from '../assets/images/menu/playNowHover.svg';
import menuButton from '../assets/images/menu/menuButton.svg';
import menuButtonHover from '../assets/images/menu/menuButtonHover.svg';

const playImg = new Image();
playImg.src = playNowButton;

const playHImg = new Image();
playHImg.src = playNowButtonHover;

const logoImg = new Image();
logoImg.src = logo;

const menuBackgroundImg = new Image();
menuBackgroundImg.src = menuBackground;

const miniBackgroundImg = new Image();
miniBackgroundImg.src = miniBackground;

const miniBackgroundMobileImg = new Image();
miniBackgroundMobileImg.src = miniBackgroundMobile;

const menuButtonImg = new Image();
menuButtonImg.src = menuButton;

const menuButtonHoverImg = new Image();
menuButtonHoverImg.src = menuButtonHover;

export const menuImages = {
  logoImg: logoImg.src,
  playImg: playImg.src,
  playHImg: playHImg.src,
  menuBackgroundImg: menuBackgroundImg.src,
  miniBackgroundImg: miniBackgroundImg.src,
  miniBackgroundMobileImg: miniBackgroundMobileImg.src,
  menuButtonImg: menuButtonImg.src,
  menuButtonHoverImg: menuButtonHoverImg.src,
};

export const sectionList = [
  { key: 'hero', value: 'HOME', page: 0 },
  { key: 'sugarKingdomOdyssey', value: 'SUGAR KINGDOM ODYSSEY', page: 1 },
  { key: 'gameView', value: 'GAME VIEW', page: 2 },
  { key: 'team', value: 'TEAM', page: 3 },
  { key: 'partners', value: 'PARTNERS', page: 4 },
  { key: 'docs', value: 'DOCUMENTS', page: 5 },
];

export const sectionListMobile = [
  { key: 'heroMobile', value: 'Home', page: 0 },
  { key: 'sugarKingdomOdysseyMobile', value: 'Sugar Kingdom Odyssey', page: 1 },
  { key: 'gameViewMobile', value: 'Game View', page: 2 },
  { key: 'teamMobile', value: 'Team', page: 3 },
  { key: 'partnersMobile', value: 'Partners', page: 4 },
  { key: 'docsMobile', value: 'Documents', page: 5 },
];
