import bg from '../assets/images/footer/bg.svg';
import bgMobile from '../assets/images/footer/bgMobile.svg';
import logo from '../assets/images/footer/logo.svg';
import telegramDisabled from '../assets/images/social/telegramButton.svg';
import telegramEnabled from '../assets/images/social/telegramHoverButton.svg';
import discordDisabled from '../assets/images/social/discordButton.svg';
import discordEnabled from '../assets/images/social/discordHoverButton.svg';
import twDisabled from '../assets/images/social/twButton.svg';
import twEnabled from '../assets/images/social/twHoverButton.svg';

const bgImg = new Image();
bgImg.src = bg;
const bgMobileImg = new Image();
bgMobileImg.src = bgMobile;

const logoImg = new Image();
logoImg.src = logo;

const tgdImg = new Image();
tgdImg.src = telegramDisabled;
const tgeImg = new Image();
tgeImg.src = telegramEnabled;

const ddImg = new Image();
ddImg.src = discordDisabled;
const deImg = new Image();
deImg.src = discordEnabled;

const tweImg = new Image();
tweImg.src = twEnabled;
const twdImg = new Image();
twdImg.src = twDisabled;

export const contactUsSection = {
  pageInfo: {
    title: 'Contact us',
    bg: bgImg.src,
    bgMobile: bgMobileImg.src,
    logo: logoImg.src,
  },
  pages: [
    {
      name: 'Home',
      toPage: 'hero',
      pageId: 0,
      toPageMobile: 'welcomeMobile',
      pageMobileId: 0,
    },
    {
      name: 'Sugar Kingdom Odyssey',
      toPage: 'sugarKingdomOdyssey',
      pageId: 1,
      toPageMobile: 'sugarKingdomOdysseyMobile',
      pageMobileId: 1,
    },
    {
      name: 'Game View',
      toPage: 'gameView',
      pageId: 2,
      toPageMobile: 'gameViewMobile',
      pageMobileId: 2,
    },
    {
      name: 'Team',
      toPage: 'team',
      pageId: 3,
      toPageMobile: 'teamMobile',
      pageMobileId: 3,
    },
    {
      name: 'Partners',
      toPage: 'partners',
      pageId: 4,
      toPageMobile: 'partnersMobile',
      pageMobileId: 4,
    },
    {
      name: 'Documents',
      toPage: 'docs',
      pageId: 5,
      toPageMobile: 'docsMobile',
      pageMobileId: 5,
    },
  ],

  socialIcons: [
    {
      name: 'telegram',
      url: 'https://t.me/SugarKingdomOfficialChat',
      imgHover: telegramEnabled,
      img: telegramDisabled,
      setImg: 1,
    },
    {
      name: 'discord',
      url: 'https://discord.gg/sugar-kingdom',
      imgHover: discordEnabled,
      img: discordDisabled,
      setImg: 2,
    },
    {
      name: 'twitter',
      url: 'https://twitter.com/SugarKingdomNFT',
      imgHover: twEnabled,
      img: twDisabled,
      setImg: 3,
    },
  ],
};
