/* eslint-disable no-lone-blocks */
import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import background from '../../../../assets/images/partners/bgMobile.svg';
import label from '../../../../assets/images/partners/label.svg';
import { Logo, sectionsPartners } from '../../../../utils/sectionPartnersNewData';

const labelImg = new Image();
labelImg.src = label;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: 'white',
  },
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    gap: '20px',
    paddingTop: '100px',
    position: 'relative',
  },
  headerImage: {
    height: '10vh',
    display: 'flex',
    indexZ: 10,
    animation: 'fadeInDown 1s ease-out',
  },
  logosContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    width: '90%',
  },
  logoImage: {
    width: '100%',
    backgroundColor: '#68030F10',
    borderRadius: '4px',
    padding: '1px',
    boxSizing: 'border-box',
    maxHeight: '45px',
  },
  logoHover: {
    cursor: 'pointer',
    transform: 'scale(1.2)',
    backgroundColor: '#00000010',
    borderRadius: '4px',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px',
    width: '6.2vh',
    maxHeight: '50px',
  },
}));

const LandingPagePartnersMobile = () => {
  const classes = useStyles();
  const [isHoverImage, setIsHoverImage] = useState(-1);
  const [hoverOn, setHoverOn] = useState('');

  const handleClickLogo = (url: string) => {
    setTimeout(() => {
      setHoverOn('');
      setIsHoverImage(-1);
      window.open(url, '_blank', 'noopener');
    }, 1000);
  };

  const PartnersGroup = (props: { data: Logo[] }) => {
    return (
      <Grid className={classes.logosContainer}>
        {props.data.map((logo, i) => (
          <Grid key={`${logo.url}`} className={classes.cardContainer}>
            <img
              aria-hidden='true'
              src={hoverOn === `logo${i}` ? logo.srcColor : logo.srcGray}
              alt='imageteam'
              className={isHoverImage === i ? `${classes.logoImage} ${classes.logoHover}` : classes.logoImage}
              onTouchStart={() => {
                setHoverOn(`logo${i}`);
                setIsHoverImage(i);
                handleClickLogo(logo.url);
              }}
              onTouchEnd={() => {
                setHoverOn('');
                setIsHoverImage(-1);
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <div className={classes.root} id='partners'>
        <Grid className={`${classes.background} ${classes.contentContainer}`}>
          <Grid container direction='column' alignItems='center' justifyContent='center'>
            <img src={labelImg.src} alt='imageheader' className={classes.headerImage} />
          </Grid>
          <PartnersGroup data={sectionsPartners.logos} />
        </Grid>
      </div>
    </>
  );
};

export default LandingPagePartnersMobile;

{
  /* <CarouselComponent className={classes.carousel}>
            <PartnersGroup data={sectionsPartners[0].logos} />
            <PartnersGroup data={sectionsPartners[1].logos} />
            <PartnersGroup data={sectionsPartners[2].logos} />
            <PartnersGroup data={sectionsPartners[3].logos} />
          </CarouselComponent> */
}
