import React, { useState } from 'react';
import { AppBar, Box, makeStyles, Toolbar } from '@material-ui/core';
import { Link } from 'react-scroll';
import { sectionList, menuImages } from '../../../../utils/sectionMenu';
import MenuDisplay from '../Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    width: '100%',
    zIndex: 1900,
  },
  appBar: {
    backgroundImage: `url(${menuImages.menuBackgroundImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    height: '80px',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('lg')]: {
      height: '110px',
    },
    [theme.breakpoints.up('xl')]: {
      height: '120px',
    },
  },
  appBarMini: {
    backgroundImage: `url(${menuImages.miniBackgroundImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    height: '100%',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('lg')]: {
      height: '120px',
    },
    [theme.breakpoints.up('xl')]: {
      height: '130px',
    },
  },
  expandButton: {
    backgroundSize: '100% 100%',
    height: '52px',
    width: '62px',
    top: '-6px',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    display: 'block',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  playButtonContainer: {
    cursor: 'pointer',
    backgroundImage: `url(${menuImages.playImg})`,
    backgroundSize: '100% 100%',
    marginRight: '72px',
    marginTop: '68px',
    width: '222px',
    height: '127px',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('xl')]: {
      width: '245px',
      height: '132px',
    },
  },
  playButtonContainerHover: {
    cursor: 'pointer',
    backgroundImage: `url(${menuImages.playHImg})`,
    backgroundSize: '100% 100%',
    marginRight: '72px',
    marginTop: '68px',
    width: '222px',
    height: '127px',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('xl')]: {
      width: '245px',
      height: '132px',
    },
  },
  toolbar: {
    padding: '0px',
    maxHeight: '100%',
    [theme.breakpoints.up('xl')]: {},
  },
  buttonLogo: {
    position: 'absolute',
    bottom: '-10px',
    left: '72px',
    cursor: 'pointer',
  },
  logoOne: {
    width: '250px',
    bottom: '5px',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      width: '250px',
      bottom: '5px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '265px',
    },
  },
  logoOneMin: {
    width: '180px',
    bottom: '-5px',
    position: 'relative',
    padding: '8px 0px 0px 40px',
  },
  containerMenuDesktop: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    width: '100%',
  },
  containerMenuMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  buttonText: {
    fontFamily: 'BloggerSans',
    fontWeight: 400,
    color: 'white',
    fontSize: '14pt',
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      fontFamily: 'BloggerSansBold',
      fontWeight: 700,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '14.5pt',
      padding: '80px 15px 0px 15px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '14.5pt',
      padding: '90px 30px 0px 30px',
    },
  },
  spacer: {
    padding: '10px',
    [theme.breakpoints.up('md')]: {
      padding: '10px',
    },
  },
  imagePlayNow: {
    position: 'relative',
  },
  buttonIconMenu: {
    position: 'absolute',
    right: '0px',
    top: '20px',
    marginRight: '30px',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      top: '10px',
    },
  },
  iconMenu: {
    width: '50px',
    height: '50px',
  },
  menuItemActive: {
    '& span': {
      fontFamily: 'BloggerSansBold',
      fontWeight: 700,
      color: '#23D9FF',
      textDecoration: 'underline #23D9FF 3px solid',
    },
  },
  menuItemActiveIndividual: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    color: '#23D9FF',
  },
  linksContainer: {
    display: 'inline-flex',
    paddingLeft: '25%',
    '& a': {
      position: 'relative',
    },
  },
  playNowLabel: {
    fontFamily: 'BlockBerthold',
    fontWeight: 400,
    textAlign: 'center',
    position: 'relative',
    display: 'block',
    fontSize: '18pt',
    marginTop: '49px',
    userSelect: 'none',
    cursor: 'pointer',
    [theme.breakpoints.up('xl')]: {
      fontSize: '20pt',
      marginTop: '49px',
    },
  },
  desktopMenu: {
    [theme.breakpoints.up(1380)]: {
      display: 'inline',
    },
    [theme.breakpoints.down(1380)]: {
      display: 'none',
    },
  },
  mobileMenu: {
    [theme.breakpoints.down(1379)]: {
      display: 'inline',
      height: 'fit-content',
      zIndex: 10,
    },
    [theme.breakpoints.up(1379)]: {
      display: 'none',
    },
  },
}));

interface props {
  handlePageChange: any;
  currentPage: number;
  handleExpandedMenu: any;
  expandedMenu: boolean;
}
const LandingPageMenu = ({ handlePageChange, currentPage, handleExpandedMenu, expandedMenu }: props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isHoveringMenuItem, setIsHoveringMenuItem] = useState('hero');
  const [playNowHover, setPlayNowHover] = useState(false);
  const [menuButtonHover, setMenuButtonHover] = useState(false);
  function openMenu() {
    setMenuOpen(!menuOpen);
  }

  const openGame = () => {
    window.open('https://game.sugarkingdom.io/', '_blank', 'noopener');
  };

  return (
    <div className={classes.root}>
      <MenuDisplay
        menuType='landpage-consumer'
        onClose={openMenu}
        open={menuOpen}
        handlePageChange={handlePageChange}
        currentPage={currentPage}></MenuDisplay>
      <>
        <div className={classes.mobileMenu}>
          <AppBar position='static' className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <div className={classes.containerMenuMobile}>
                <Link activeClass='active' to='hero' spy={true} smooth={true} offset={0} duration={500}>
                  <span onClick={() => handlePageChange(0)} onFocus={() => void 0} aria-hidden='true'>
                    <img className={classes.logoOneMin} src={menuImages.logoImg} alt='Logo' />
                  </span>
                </Link>
                <button
                  onMouseEnter={() => setMenuButtonHover(true)}
                  onMouseLeave={() => setMenuButtonHover(false)}
                  onPointerEnter={() => setMenuButtonHover(true)}
                  onPointerLeave={() => setMenuButtonHover(false)}
                  className={`${classes.buttonIconMenu}`}
                  onClick={openMenu}>
                  <img
                    className={classes.iconMenu}
                    src={!menuButtonHover ? menuImages.menuButtonImg : menuImages.menuButtonHoverImg}
                    alt='menu'
                  />
                </button>
              </div>
            </Toolbar>
          </AppBar>
        </div>

        <div className={classes.desktopMenu}>
          <AppBar
            position='static'
            //className={currentPage === 0 || expandedMenu === true ? classes.appBar : classes.appBarMini}>
            className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              {/* {(currentPage === 0 || expandedMenu === true) && ( */}
              <div
                className={classes.containerMenuDesktop}
                onMouseLeave={(e) => handleExpandedMenu(false)}
                onFocus={() => void 0}
                aria-hidden='true'>
                <div className={classes.linksContainer}>
                  <span
                    onClick={() => handlePageChange(0)}
                    onFocus={() => void 0}
                    aria-hidden='true'
                    className={classes.buttonLogo}>
                    <img className={classes.logoOne} src={menuImages.logoImg} alt='Logo' />
                  </span>
                  {sectionList.map((section) => (
                    <span
                      key={section.key}
                      onFocus={() => void 0}
                      aria-hidden='true'
                      onMouseOver={(e) => setIsHoveringMenuItem(section.key)}
                      onMouseLeave={(e) => setIsHoveringMenuItem('')}
                      onClick={() => handlePageChange(section.page)}
                      className={`${classes.buttonText} ${
                        isHoveringMenuItem === section.key || currentPage === section.page
                          ? classes.menuItemActiveIndividual
                          : ''
                      }`}>
                      {section.value}
                    </span>
                  ))}
                </div>
                <Box
                  className={playNowHover ? classes.playButtonContainerHover : classes.playButtonContainer}
                  onMouseOver={(e) => setPlayNowHover(true)}
                  onMouseLeave={(e) => setPlayNowHover(false)}
                  onClick={() => openGame()}>
                  <span className={classes.playNowLabel} aria-hidden='true'></span>
                </Box>
              </div>
              {/* )} */}
              {/* {currentPage !== 0 && !expandedMenu && (
                <div
                  onMouseOver={(e) => handleExpandedMenu(true)}
                  onFocus={() => void 0}
                  aria-hidden='true'
                  className={classes.expandButton}></div>
              )} */}
            </Toolbar>
          </AppBar>
        </div>
      </>
    </div>
  );
};

export default LandingPageMenu;
