import bg from '../assets/images/sugarKingdomOdyssey/bg.svg';
import bgMobile from '../assets/images/sugarKingdomOdyssey/bgShadowMobile.svg';
import goal1header from '../assets/images/sugarKingdomOdyssey/icon_1.svg';
import goal2header from '../assets/images/sugarKingdomOdyssey/icon_2.svg';
import goal3header from '../assets/images/sugarKingdomOdyssey/icon_3.svg';
import goal1headerM from '../assets/images/sugarKingdomOdyssey/iconMobile_1.svg';
import goal2headerM from '../assets/images/sugarKingdomOdyssey/iconMobile_2.svg';
import goal3headerM from '../assets/images/sugarKingdomOdyssey/iconMobile_3.svg';
import char1 from '../assets/images/sugarKingdomOdyssey/char_1.svg';
import char2 from '../assets/images/sugarKingdomOdyssey/char_2.svg';
import char3 from '../assets/images/sugarKingdomOdyssey/char_3.svg';

const bgImg = new Image();
bgImg.src = bg;

const bgMobileImg = new Image();
bgMobileImg.src = bgMobile;

const image1Char = new Image();
image1Char.src = char1;

const image1Header = new Image();
image1Header.src = goal1header;

const image2Char = new Image();
image2Char.src = char2;

const image2Header = new Image();
image2Header.src = goal2header;

const image3Char = new Image();
image3Char.src = char3;

const image3Header = new Image();
image3Header.src = goal3header;

const image1HeaderM = new Image();
image1HeaderM.src = goal1headerM;

const image2HeaderM = new Image();
image2HeaderM.src = goal2headerM;

const image3HeaderM = new Image();
image3HeaderM.src = goal3headerM;

export const sectionsGoals = {
  pageInfo: {
    bg: bgImg.src,
    bgMobile: bgMobileImg.src,
  },
  sections: [
    {
      name: 'goal1',
      title: 'Universal Crypto Games',
      text: [
        'SUGAR KINGDOM is the only gaming',
        'platform in which projects from all',
        'blockchains can bring direct utility to',
        'their tokens by playing games.',
      ],
      imageHeader: image1Header.src,
      imageHeaderM: image1HeaderM.src,
      slider1: image1Char.src,
    },
    {
      name: 'goal2',
      title: 'More than NFTs!',
      text: [
        'Bring your tokens from any chain, climb',
        'the leaderboard and claim your spot at',
        'the top for epic rewards. Your crypto key',
        'opens the door to the Kingdom’s treasury!',
      ],
      imageHeader: image2Header.src,
      imageHeaderM: image2HeaderM.src,
      slider2: image2Char.src,
    },
    {
      name: 'goal3',
      title: 'Made For The Long-Run',
      text: [
        `Every game holds the potential to`,
        `skyrocket your winnings by up to 100X!`,
        `Play, conquer and multiply your crypto`,
        `rewards in a realm where fortune waits for you.`,
      ],
      imageHeader: image3Header.src,
      imageHeaderM: image3HeaderM.src,
      slider3: image3Char.src,
    },
  ],
};
