/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import { sectionDocs } from '../../../../utils/sectionDocsData';
import CarouselComponent from '../landingPageMobile/Carousel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: 'white',
  },
  background: {
    backgroundImage: `url(${sectionDocs.pageInfo.bgMobile})`,
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    gap: '20px',
    paddingTop: '100px',
    position: 'relative',
  },
  headerImage: {
    width: '80vw',
    display: 'flex',
    indexZ: 10,
    animation: 'fadeInDown 1s ease-out',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: 2,
  },
  cardContent: {
    zIndex: 9,
    justifyContent: 'start',
    marginLeft: '10px',
    padding: '0px 50px',
    marginTop: '10vh',
    height: '40vh',
  },
  imageDoc: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
  },
  iconImage: {
    marginTop: '4vh',
    width: '12vh',
  },
  cardText: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    textAlign: 'center',
    color: '#68030F',
    lineHeight: '20px',
    fontSize: '18px',
    marginTop: '2vh',
    padding: '0px 10px',
  },
  buttonImage: {
    marginTop: '2vh',
    width: '15vh',
  },
  carousel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '58vh',
    width: '100%',
    padding: '0',
    '& ul': {
      height: '58vh',
      width: '100%',
    },
    '& > div > div': {
      height: '58vh',
      marginTop: '-15px !important',
    },
  },
  token: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    objectPosition: 'center center',
    zIndex: -1,
  },
  whitePaper: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    objectPosition: 'center center',
    zIndex: -1,
  },
}));

const LandingPageDocsMobile = () => {
  const classes = useStyles();
  const [tokenHover, setTokenHover] = useState<boolean>(false);
  const [whiteHover, setWhiteHover] = useState<boolean>(false);

  const handleButton = (link: string) => {
    window.open(link, '_blank', 'noopener');
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='space-between'
        className={`${classes.background} ${classes.contentContainer}`}>
        <img
          aria-hidden='true'
          src={sectionDocs.pageInfo.titleMobileImg}
          alt='imageheader'
          className={classes.headerImage}
        />
        <CarouselComponent className={classes.carousel}>
          <img
            onMouseEnter={() => setTokenHover(true)}
            onMouseLeave={() => setTokenHover(false)}
            onPointerEnter={() => setTokenHover(true)}
            onPointerLeave={() => setTokenHover(false)}
            src={tokenHover ? sectionDocs.cards[0].image : sectionDocs.cards[0].hover}
            alt='token'
            className={`${classes.token}`}
            onClick={() => handleButton(sectionDocs.cards[0].url)}
          />
          <img
            onMouseEnter={() => setWhiteHover(true)}
            onMouseLeave={() => setWhiteHover(false)}
            onPointerEnter={() => setWhiteHover(true)}
            onPointerLeave={() => setWhiteHover(false)}
            src={whiteHover ? sectionDocs.cards[1].image : sectionDocs.cards[1].hover}
            alt='whitePaper'
            className={`${classes.whitePaper}`}
            onClick={() => handleButton(sectionDocs.cards[1].url)}
          />
        </CarouselComponent>
      </Grid>
    </div>
  );
};

export default LandingPageDocsMobile;
