import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { sectionsGoals } from '../../../../utils/sectionKingdomSugarData';
import CarouselComponent from './Carousel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: 'white',
  },
  background: {
    backgroundImage: `url(${sectionsGoals.pageInfo.bgMobile})`,
    backgroundSize: 'cover',
    height: '100vh',
    zIndex: -2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bodyContainer: {
    maxHeight: '90%',
  },
  contentContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: '0',
    margin: '0',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '30vh',
    listStyle: 'none',
    height: '100%',
    width: '100vw',
    gap: '10px',
  },
  contentText: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    textAlign: 'center',
    width: '55vw',
    indexZ: 2,
    fontSize: '4.5vw',
  },
  text: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    textAlign: 'center',
    fontSize: '18px',
    marginTop: '12px',
  },
  carousel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70vh',
    width: '100%',
    padding: '0',
    '& ul': {
      height: '70vh',
      width: '100%',
    },
    '& > div > div': {
      height: '70vh',
      marginTop: '-15px !important',
    },
  },
}));

const LandingSugarKingdomOdysseyMobile = () => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  console.log('currentIndex', currentIndex);
  return (
    <>
      <div className={classes.root} id='sugarKingdomOdysseyMobile'>
        <Grid container direction='column' alignItems='center' className={classes.background}>
          <Grid container className={`${classes.bodyContainer}`}>
            <CarouselComponent currentIndex={setCurrentIndex} className={classes.carousel}>
              {sectionsGoals.sections.map((section, index) => (
                <Fragment key={index}>
                  <Grid
                    direction='row'
                    container
                    alignItems='center'
                    justifyContent='center'
                    className={classes.contentContainer}>
                    <img
                      src={sectionsGoals.sections[index].imageHeaderM}
                      alt='section'
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain',
                        objectPosition: 'center center',
                        zIndex: -1,
                      }}
                    />
                    <Grid className={classes.textContainer}>
                      {sectionsGoals.sections[index].text.map((text, i) => (
                        <li className={classes.contentText} key={`goal${i}`}>
                          {text}
                        </li>
                      ))}
                    </Grid>
                  </Grid>
                </Fragment>
              ))}
            </CarouselComponent>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LandingSugarKingdomOdysseyMobile;
