/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import { makeStyles, Theme, Grid } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import background from '../../../../assets/images/teamAdv/bg.svg';
import char1 from '../../../../assets/images/teamAdv/char1.svg';
import char2 from '../../../../assets/images/teamAdv/char2.svg';
import label from '../../../../assets/images/teamAdv/label.svg';
import logoEnabled from '../../../../assets/images/teamAdv/logoEnabled.svg';
import logoDisabled from '../../../../assets/images/teamAdv/logoDisabled.svg';
import { useScroll } from '../../../../hooks/useScroll';

const char1Img = new Image();
char1Img.src = char1;

const char2Img = new Image();
char2Img.src = char2;

const labelImg = new Image();
labelImg.src = label;

const logoEnabledImg = new Image();
logoEnabledImg.src = logoEnabled;

const logoDisabledImg = new Image();
logoDisabledImg.src = logoDisabled;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: 'white',
  },
  mainContainer: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    position: 'relative',
    display: 'flex',
    justifySelf: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingTop: '100px',
    zIndex: 1,
    [theme.breakpoints.only('md')]: {},
    [theme.breakpoints.only('xl')]: {},
  },
  logo: {
    width: '400px',
    height: '400px',
    cursor: 'pointer',
    animation: 'zoom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.up('xl')]: {},
  },
  imageChar1: {
    width: '30vw',
    display: 'block',
    position: 'absolute',
    bottom: '120px',
    left: '8vw',
    indexZ: 10,
    animation: 'slide-in-left 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
    [theme.breakpoints.down(900)]: {
      display: 'none',
    },
  },
  imageChar2: {
    width: '25vw',
    display: 'block',
    position: 'absolute',
    bottom: '120px',
    right: '10vw',
    indexZ: 10,
    animation: 'slide-in-left 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
    [theme.breakpoints.down(900)]: {
      display: 'none',
    },
  },
  label: {
    width: '450px',
    display: 'block',
    position: 'absolute',
    top: '18.5vh',
    margin: 'auto',
    indexZ: 10,
    animation: 'slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
    [theme.breakpoints.only('md')]: {
      top: '16.5vh',
      width: '500px',
    },
    [theme.breakpoints.only('lg')]: {
      top: '15.5vh',
      width: '550px',
    },
    [theme.breakpoints.only('xl')]: {
      top: '14.5vh',
      width: '600px',
    },
  },
}));

const LandingPageTeamAndADV = () => {
  const classes = useStyles();
  const refSection = useRef(null);
  const [logoHover, setLogoHover] = useState<boolean>(false);
  const { scrollPosition } = useScroll();

  const handleButton = (link: string) => {
    window.open(link, '_blank', 'noopener');
  };

  return (
    <div className={classes.root} id='team'>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='space-between'
        className={classes.mainContainer}
        ref={refSection}>
        {scrollPosition === 3 && (
          <Grid direction='row' container alignItems='center' justifyContent='center' className={classes.gridContainer}>
            <img
              onMouseEnter={() => setLogoHover(true)}
              onMouseLeave={() => setLogoHover(false)}
              onPointerEnter={() => setLogoHover(true)}
              onPointerLeave={() => setLogoHover(false)}
              src={logoHover ? logoEnabledImg.src : logoDisabledImg.src}
              alt='logo'
              className={`${classes.logo}`}
              onClick={() => handleButton('https://apesgroup.xyz/')}
            />

            <img src={labelImg.src} alt='game view' className={`${classes.label}`} />
            <img src={char1Img.src} alt='imageChar1' className={`${classes.imageChar1}`} />
            <img src={char2Img.src} alt='imageChar2' className={`${classes.imageChar2}`} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default LandingPageTeamAndADV;
