/* eslint-disable jsx-a11y/media-has-caption */
import { makeStyles, Theme, Grid } from '@material-ui/core';
import React, { useRef } from 'react';
import { sectionsGameView, gameViewImages } from '../../../../utils/sectionGameViewData';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundImage: `url(${gameViewImages.backgroundMobile})`,
    backgroundSize: 'cover',
    height: '100vh',
    width: '100VW',
    flexGrow: 1,
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    background: 'black',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    gap: '5vh',
    paddingTop: '100px',
    '&>iframe': {
      width: '80vw',
    },
  },
  videoVimeo: {
    borderImage: `url(${gameViewImages.border}) 0 20 stretch`,
    borderImageSlice: '60',
    borderWidth: '25px',
    borderStyle: 'solid',
    width: '100%',
    height: '30vh',
    animation: 'fadeoutfadeinTeam 1s linear',
  },
  imageChar1: {
    height: '20vh',
    display: 'block',
    position: 'absolute',
    bottom: '13vh',
    left: '10vw',
    indexZ: 10,
    animation: 'fadeInLeft 1s ease-out',
  },
  imageChar2: {
    height: '20vh',
    display: 'block',
    position: 'absolute',
    bottom: '13vh',
    right: '10vw',
    indexZ: 10,
    animation: 'fadeInRight 1s ease-out',
  },
  label: {
    height: '10vh',
    display: 'flex',
    indexZ: 10,
    animation: 'fadeInDown 1s ease-out',
    top: '0',
  },
}));

const LandingPageGameViewMobile = () => {
  const classes = useStyles();
  const videoRef1 = useRef(null);

  return (
    <>
      <div className={classes.root}>
        <Grid className={classes.gridContainer}>
          <img src={gameViewImages.gameView} alt='game view' className={`${classes.label}`} />
          <iframe
            width='100%'
            height='100%'
            ref={videoRef1}
            className={classes.videoVimeo}
            src={sectionsGameView[0].src}
            allow='fullscreen; accelerometer; autoplay; encrypted-media; picture-in-picture'
            allowFullScreen
            title='video'
          />
          <img src={gameViewImages.char1} alt='imageChar1' className={`${classes.imageChar1}`} />
          <img src={gameViewImages.char2} alt='imageChar2' className={`${classes.imageChar2}`} />
        </Grid>
      </div>
    </>
  );
};

export default LandingPageGameViewMobile;
