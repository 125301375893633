import background from '../assets/images/gameView/bg.svg';
import backgroundMobile from '../assets/images/gameView/mobileBg.svg';
import border from '../assets/images/gameView/border.svg';
import char1 from '../assets/images/gameView/char1.svg';
import char2 from '../assets/images/gameView/char2.svg';
import gameView from '../assets/images/gameView/gameView.svg';
import gameViewMobile from '../assets/images/gameView/gameViewMobile.svg';

const char1Img = new Image();
char1Img.src = char1;

const char2Img = new Image();
char2Img.src = char2;

const gameViewImg = new Image();
gameViewImg.src = gameView;

const backgroundImg = new Image();
backgroundImg.src = background;

const backgroundMobileImg = new Image();
backgroundMobileImg.src = backgroundMobile;

const borderImg = new Image();
borderImg.src = border;

const gameViewMobileImg = new Image();
gameViewMobileImg.src = gameViewMobile;

export const gameViewImages = {
  char1: char1Img.src,
  char2: char2Img.src,
  gameView: gameViewImg.src,
  gameViewMobile: gameViewMobileImg.src,
  background: backgroundImg.src,
  backgroundMobile: backgroundMobileImg.src,
  border: borderImg.src,
};

export const sectionsGameView = [
  {
    name: 'GameView1',
    src: 'https://www.youtube.com/embed/LRJvudUP_2M?si=sPXOU_W0_a4dV_JA??autoplay=1&cc_load_policy=1&enablejsapi=1&loop=1&start=1',
  },
  {
    name: 'GameView2',
    src: 'https://www.youtube.com/embed/-DwwULON6fA',
  },
  {
    name: 'GameView3',
    src: 'https://player.vimeo.com/progressive_redirect/playback/676386554/rendition/1080p?loc=external&signature=302b242c65c16a056801ebbd5b221ce5a3b5b41815050d8582d66e4c38512db9',
  },
];
