import background from '../assets/images/header/bg.svg';
import backgroundMobile from '../assets/images/header/bgMobile.svg';
import Group from '../assets/images/header/group.svg';
import GroupHover from '../assets/images/header/groupHover.svg';
import LogoBg from '../assets/images/header/bgLogo.svg';

const GroupImg = new Image();
GroupImg.src = Group;

const GroupHoverImg = new Image();
GroupHoverImg.src = GroupHover;

const backgroundImg = new Image();
backgroundImg.src = background;

const backgroundMobileImg = new Image();
backgroundMobileImg.src = backgroundMobile;

const LogoBgImg = new Image();
LogoBgImg.src = LogoBg;

export const headerImages = {
  LogoBgImg: LogoBgImg.src,
  GroupImg: GroupImg.src,
  GroupHoverImg: GroupHoverImg.src,
  backgroundImg: backgroundImg.src,
  backgroundMobileImg: backgroundMobileImg.src,
};
