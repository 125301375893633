import { create } from "zustand";

export interface IScrollStore {
  ref: string;
  scrollPosition: number;
  setRef: (value: string) => void;
  setScrollPosition: (value: number) => void;
}

export const useScroll = create<IScrollStore>((set) => ({
  ref: "",
  scrollPosition: 0,
  setRef: (value) => set({ ref: value }),
  setScrollPosition: (value) => set({ scrollPosition: value }),
}));
