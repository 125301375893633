import bg from '../assets/images/social/socialContainer.svg';
import bgMobile from '../assets/images/social/socialContainerMobile.svg';
import telegramDisabled from '../assets/images/social/telegramButton.svg';
import telegramEnabled from '../assets/images/social/telegramHoverButton.svg';
import discordDisabled from '../assets/images/social/discordButton.svg';
import discordEnabled from '../assets/images/social/discordHoverButton.svg';
import twDisabled from '../assets/images/social/twButton.svg';
import twEnabled from '../assets/images/social/twHoverButton.svg';

const bgImg = new Image();
bgImg.src = bg;
const bgMobileImg = new Image();
bgMobileImg.src = bgMobile;

const tgdImg = new Image();
tgdImg.src = telegramDisabled;
const tgeImg = new Image();
tgeImg.src = telegramEnabled;

const ddImg = new Image();
ddImg.src = discordDisabled;
const deImg = new Image();
deImg.src = discordEnabled;

const tweImg = new Image();
tweImg.src = twEnabled;
const twdImg = new Image();
twdImg.src = twDisabled;

export const socialData = {
  pageInfo: {
    title: 'Social',
    bgWeb: bgImg.src,
    bgMobile: bgMobileImg.src,
  },
  icons: {
    discordDisabled: ddImg.src,
    discordEnabled: deImg.src,
    twDisabled: twdImg.src,
    twEnabled: tweImg.src,
    telegramDisabled: tgdImg.src,
    telegramEnabled: tgeImg.src,
  },
  links: {
    discord: 'https://discord.gg/sugar-kingdom',
    telegram: 'https://t.me/SugarKingdomOfficialChat',
    twitter: 'https://twitter.com/SugarKingdomNFT',
  },
};
