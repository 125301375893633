import partner01 from '../assets/images/partners/partners/1.png';
import partner02 from '../assets/images/partners/partners/2.png';
import partner03 from '../assets/images/partners/partners/3.png';
import partner04 from '../assets/images/partners/partners/4.png';
import partner05 from '../assets/images/partners/partners/5.png';
import partner06 from '../assets/images/partners/partners/6.png';
import partner07 from '../assets/images/partners/partners/7.png';
import partner08 from '../assets/images/partners/partners/8.png';
import partner09 from '../assets/images/partners/partners/9.png';
import partner10 from '../assets/images/partners/partners/10.png';
import partner11 from '../assets/images/partners/partners/11.png';
import partner12 from '../assets/images/partners/partners/12.png';
import partner13 from '../assets/images/partners/partners/13.png';
import partner14 from '../assets/images/partners/partners/14.png';
import partner15 from '../assets/images/partners/partners/15.png';
import partner16 from '../assets/images/partners/partners/16.png';
import partner17 from '../assets/images/partners/partners/17.png';
import partner18 from '../assets/images/partners/partners/18.png';
import partner19 from '../assets/images/partners/partners/19.png';
import partner20 from '../assets/images/partners/partners/20.png';
import partner21 from '../assets/images/partners/partners/21.png';
import partner22 from '../assets/images/partners/partners/22.png';
import partner23 from '../assets/images/partners/partners/23.png';
import partner24 from '../assets/images/partners/partners/24.png';
import partner25 from '../assets/images/partners/partners/25.png';
import partner26 from '../assets/images/partners/partners/26.png';
import partner27 from '../assets/images/partners/partners/27.png';
import partner28 from '../assets/images/partners/partners/28.png';
import partner29 from '../assets/images/partners/partners/29.png';
import partner30 from '../assets/images/partners/partners/30.png';
import partner31 from '../assets/images/partners/partners/31.png';
import partner32 from '../assets/images/partners/partners/32.png';
import partner33 from '../assets/images/partners/partners/33.png';
import partner34 from '../assets/images/partners/partners/34.png';
import partner35 from '../assets/images/partners/partners/35.png';
import partner36 from '../assets/images/partners/partners/36.png';
import partner37 from '../assets/images/partners/partners/37.png';
import partner38 from '../assets/images/partners/partners/38.png';
import partner39 from '../assets/images/partners/partners/39.png';
import partner40 from '../assets/images/partners/partners/40.png';
import partner41 from '../assets/images/partners/partners/41.png';
import partner42 from '../assets/images/partners/partners/42.png';
import partner43 from '../assets/images/partners/partners/43.png';
import partner44 from '../assets/images/partners/partners/44.png';
import partner45 from '../assets/images/partners/partners/45.png';
import partner46 from '../assets/images/partners/partners/46.png';
import partner47 from '../assets/images/partners/partners/47.png';
import partner48 from '../assets/images/partners/partners/48.png';
import partner49 from '../assets/images/partners/partners/49.png';
import partner50 from '../assets/images/partners/partners/50.png';
import partner51 from '../assets/images/partners/partners/51.png';
import partner52 from '../assets/images/partners/partners/52.png';
import partner53 from '../assets/images/partners/partners/53.png';
import partner54 from '../assets/images/partners/partners/54.png';
import partner55 from '../assets/images/partners/partners/55.png';
import partner56 from '../assets/images/partners/partners/56.png';
import partner57 from '../assets/images/partners/partners/57.png';
import partner58 from '../assets/images/partners/partners/58.png';
import partner59 from '../assets/images/partners/partners/59.png';
import partner60 from '../assets/images/partners/partners/60.png';
import partner61 from '../assets/images/partners/partners/61.png';
import partner62 from '../assets/images/partners/partners/62.png';
import partner63 from '../assets/images/partners/partners/63.png';
import partner64 from '../assets/images/partners/partners/64.png';
import partner65 from '../assets/images/partners/partners/65.png';
import partner66 from '../assets/images/partners/partners/66.png';
import partner67 from '../assets/images/partners/partners/67.png';
import partner68 from '../assets/images/partners/partners/68.png';
import partner69 from '../assets/images/partners/partners/69.png';
import partner70 from '../assets/images/partners/partners/70.png';
import partner71 from '../assets/images/partners/partners/71.png';
import partner72 from '../assets/images/partners/partners/72.png';
import partner73 from '../assets/images/partners/partners/73.png';
import partner74 from '../assets/images/partners/partners/74.png';
import partner75 from '../assets/images/partners/partners/75.png';
import partner76 from '../assets/images/partners/partners/76.png';
import partner77 from '../assets/images/partners/partners/77.png';
import partner78 from '../assets/images/partners/partners/78.png';
import partner79 from '../assets/images/partners/partners/79.png';
import partner80 from '../assets/images/partners/partners/80.png';
import partner81 from '../assets/images/partners/partners/81.png';
import partner82 from '../assets/images/partners/partners/82.png';

// Docs: https://docs.google.com/spreadsheets/d/1ZTjlyfox8YIAw8W8ebdX2mQwd8ew12WSj0RISGIz178/edit#gid=0

const partner01Img = new Image();
partner01Img.src = partner01;
const partner02Img = new Image();
partner02Img.src = partner02;
const partner03Img = new Image();
partner03Img.src = partner03;
const partner04Img = new Image();
partner04Img.src = partner04;
const partner05Img = new Image();
partner05Img.src = partner05;
const partner06Img = new Image();
partner06Img.src = partner06;
const partner07Img = new Image();
partner07Img.src = partner07;
const partner08Img = new Image();
partner08Img.src = partner08;
const partner09Img = new Image();
partner09Img.src = partner09;
const partner10Img = new Image();
partner10Img.src = partner10;
const partner11Img = new Image();
partner11Img.src = partner11;
const partner12Img = new Image();
partner12Img.src = partner12;
const partner13Img = new Image();
partner13Img.src = partner13;
const partner14Img = new Image();
partner14Img.src = partner14;
const partner15Img = new Image();
partner15Img.src = partner15;
const partner16Img = new Image();
partner16Img.src = partner16;
const partner17Img = new Image();
partner17Img.src = partner17;
const partner18Img = new Image();
partner18Img.src = partner18;
const partner19Img = new Image();
partner19Img.src = partner19;
const partner20Img = new Image();
partner20Img.src = partner20;
const partner21Img = new Image();
partner21Img.src = partner21;
const partner22Img = new Image();
partner22Img.src = partner22;
const partner23Img = new Image();
partner23Img.src = partner23;
const partner24Img = new Image();
partner24Img.src = partner24;
const partner25Img = new Image();
partner25Img.src = partner25;
const partner26Img = new Image();
partner26Img.src = partner26;
const partner27Img = new Image();
partner27Img.src = partner27;
const partner28Img = new Image();
partner28Img.src = partner28;
const partner29Img = new Image();
partner29Img.src = partner29;
const partner30Img = new Image();
partner30Img.src = partner30;
const partner31Img = new Image();
partner31Img.src = partner31;
const partner32Img = new Image();
partner32Img.src = partner32;
const partner33Img = new Image();
partner33Img.src = partner33;
const partner34Img = new Image();
partner34Img.src = partner34;
const partner35Img = new Image();
partner35Img.src = partner35;
const partner36Img = new Image();
partner36Img.src = partner36;
const partner37Img = new Image();
partner37Img.src = partner37;
const partner38Img = new Image();
partner38Img.src = partner38;
const partner39Img = new Image();
partner39Img.src = partner39;
const partner40Img = new Image();
partner40Img.src = partner40;
const partner41Img = new Image();
partner41Img.src = partner41;
const partner42Img = new Image();
partner42Img.src = partner42;
const partner43Img = new Image();
partner43Img.src = partner43;
const partner44Img = new Image();
partner44Img.src = partner44;
const partner45Img = new Image();
partner45Img.src = partner45;
const partner46Img = new Image();
partner46Img.src = partner46;
const partner47Img = new Image();
partner47Img.src = partner47;
const partner48Img = new Image();
partner48Img.src = partner48;
const partner49Img = new Image();
partner49Img.src = partner49;
const partner50Img = new Image();
partner50Img.src = partner50;
const partner51Img = new Image();
partner51Img.src = partner51;
const partner52Img = new Image();
partner52Img.src = partner52;
const partner53Img = new Image();
partner53Img.src = partner53;
const partner54Img = new Image();
partner54Img.src = partner54;
const partner55Img = new Image();
partner55Img.src = partner55;
const partner56Img = new Image();
partner56Img.src = partner56;
const partner57Img = new Image();
partner57Img.src = partner57;
const partner58Img = new Image();
partner58Img.src = partner58;
const partner59Img = new Image();
partner59Img.src = partner59;
const partner60Img = new Image();
partner60Img.src = partner60;
const partner61Img = new Image();
partner61Img.src = partner61;
const partner62Img = new Image();
partner62Img.src = partner62;
const partner63Img = new Image();
partner63Img.src = partner63;
const partner64Img = new Image();
partner64Img.src = partner64;
const partner65Img = new Image();
partner65Img.src = partner65;
const partner66Img = new Image();
partner66Img.src = partner66;
const partner67Img = new Image();
partner67Img.src = partner67;
const partner68Img = new Image();
partner68Img.src = partner68;
const partner69Img = new Image();
partner69Img.src = partner69;
const partner70Img = new Image();
partner70Img.src = partner70;
const partner71Img = new Image();
partner71Img.src = partner71;
const partner72Img = new Image();
partner72Img.src = partner72;
const partner73Img = new Image();
partner73Img.src = partner73;
const partner74Img = new Image();
partner74Img.src = partner74;
const partner75Img = new Image();
partner75Img.src = partner75;
const partner76Img = new Image();
partner76Img.src = partner76;
const partner77Img = new Image();
partner77Img.src = partner77;
const partner78Img = new Image();
partner78Img.src = partner78;
const partner79Img = new Image();
partner79Img.src = partner79;
const partner80Img = new Image();
partner80Img.src = partner80;
const partner81Img = new Image();
partner81Img.src = partner81;
const partner82Img = new Image();
partner82Img.src = partner82;

export interface Logo {
  srcGray: string;
  srcColor: string;
  url: string;
  name: string;
  order: number;
}
export interface SectionPartners {
  name: string;
  logos: Logo[];
}

export const sectionsPartners: SectionPartners = {
  name: 'Partners',
  logos: [
    {
      srcGray: partner01Img.src,
      srcColor: partner01Img.src,
      name: "7o'clock Capital",
      url: 'https://www.7oclockcapital.com/',
      order: 1,
    },
    {
      srcGray: partner02Img.src,
      srcColor: partner02Img.src,
      name: 'AcknoLedger',
      url: 'https://acknoledger.com/',
      order: 2,
    },
    {
      srcGray: partner03Img.src,
      srcColor: partner03Img.src,
      name: 'Alpha Hunt',
      url: 'https://linktr.ee/alphahunt',
      order: 3,
    },
    {
      srcGray: partner04Img.src,
      srcColor: partner04Img.src,
      name: 'Alpha Ventures',
      url: 'https://www.alphaventure.xyz/',
      order: 4,
    },
    {
      srcGray: partner05Img.src,
      srcColor: partner05Img.src,
      name: 'Aza Ventures',
      url: 'https://aza.ventures/',
      order: 5,
    },
    {
      srcGray: partner06Img.src,
      srcColor: partner06Img.src,
      name: 'OORT',
      url: 'https://www.oortech.com/',
      order: 6,
    },
    {
      srcGray: partner07Img.src,
      srcColor: partner07Img.src,
      name: 'Project Hive',
      url: 'https://project-hive.io/',
      order: 7,
    },
    {
      srcGray: partner08Img.src,
      srcColor: partner08Img.src,
      name: 'Blue Wheel Capital',
      url: 'https://www.bluewheelcapital.com/',
      order: 8,
    },
    {
      srcGray: partner09Img.src,
      srcColor: partner09Img.src,
      name: 'Brotherhood Ventures',
      url: 'https://brotherhood.ventures/',
      order: 9,
    },
    {
      srcGray: partner10Img.src,
      srcColor: partner10Img.src,
      name: 'Magic Craft',
      url: 'https://magiccraft.io/',
      order: 10,
    },
    {
      srcGray: partner11Img.src,
      srcColor: partner11Img.src,
      name: 'Codexchain',
      url: 'https://codexchain.xyz/home',
      order: 11,
    },
    {
      srcGray: partner12Img.src,
      srcColor: partner12Img.src,
      name: 'Dormint',
      url: 'https://twitter.com/Dormint_io',
      order: 12,
    },
    {
      srcGray: partner13Img.src,
      srcColor: partner13Img.src,
      name: 'Edu3labs',
      url: 'https://edu3labs.com/',
      order: 13,
    },
    {
      srcGray: partner14Img.src,
      srcColor: partner14Img.src,
      name: 'CryptoFomo',
      url: 'https://www.youtube.com/c/CryptoFOMO/videos',
      order: 14,
    },
    {
      srcGray: partner15Img.src,
      srcColor: partner15Img.src,
      name: 'Get Pip',
      url: 'https://pip.me/pip',
      order: 15,
    },
    {
      srcGray: partner16Img.src,
      srcColor: partner16Img.src,
      name: 'DaoLaunch',
      url: 'https://daolaunch.net/',
      order: 16,
    },
    {
      srcGray: partner17Img.src,
      srcColor: partner17Img.src,
      name: 'Trustpad',
      url: 'https://trustpad.io/',
      order: 17,
    },
    // {
    //   srcGray: partner18Img.src,
    //   srcColor: partner18Img.src,
    //   name: 'Dutch Crypto Investors',
    //   url: 'https://dutchcryptoinvestors.com/',
    //   order: 18,
    // },
    {
      srcGray: partner19Img.src,
      srcColor: partner19Img.src,
      name: 'Empire VC',
      url: 'https://www.empire-vc.com/',
      order: 19,
    },
    {
      srcGray: partner20Img.src,
      srcColor: partner20Img.src,
      name: 'GameFi Capital',
      url: '',
      order: 20,
    },
    {
      srcGray: partner21Img.src,
      srcColor: partner21Img.src,
      name: 'Hela Network',
      url: 'https://helalabs.com/',
      order: 21,
    },
    {
      srcGray: partner22Img.src,
      srcColor: partner22Img.src,
      name: 'Karat Dao',
      url: 'https://drive.google.com/drive/folders/1WmUUBPKtrm5UDOjNUwD23GRk652R3VdI',
      order: 22,
    },
    {
      srcGray: partner23Img.src,
      srcColor: partner23Img.src,
      name: 'Metavest Capital',
      url: 'https://metavest.capital/',
      order: 23,
    },
    {
      srcGray: partner24Img.src,
      srcColor: partner24Img.src,
      name: 'AVG',
      url: 'https://twitter.com/AutoVentureGr',
      order: 24,
    },
    {
      srcGray: partner25Img.src,
      srcColor: partner25Img.src,
      name: 'Halvins Capital',
      url: 'http://halvingscapital.com/',
      order: 25,
    },
    {
      srcGray: partner26Img.src,
      srcColor: partner26Img.src,
      name: 'IndiGG Dao',
      url: 'https://drive.google.com/file/d/1QO9Tsr0Tk83sHWPphkOOV_gpqMwy_pxb/view',
      order: 26,
    },
    {
      srcGray: partner27Img.src,
      srcColor: partner27Img.src,
      name: 'Patex',
      url: 'https://patex.io/es',
      order: 27,
    },
    {
      srcGray: partner28Img.src,
      srcColor: partner28Img.src,
      name: 'Kommunitas',
      url: 'https://kommunitas.net/',
      order: 28,
    },
    {
      srcGray: partner29Img.src,
      srcColor: partner29Img.src,
      name: 'Legion Ventures',
      url: 'https://legion.ventures/',
      order: 29,
    },
    {
      srcGray: partner30Img.src,
      srcColor: partner30Img.src,
      name: 'Moonstarter',
      url: 'https://moonstarter.net/',
      order: 30,
    },
    {
      srcGray: partner31Img.src,
      srcColor: partner31Img.src,
      name: 'Vision Game',
      url: 'https://drive.google.com/drive/folders/1yd3kW_rdT72EIdWil9SceNktiJQ8G9Hg',
      order: 31,
    },
    {
      srcGray: partner32Img.src,
      srcColor: partner32Img.src,
      name: 'SafeLaunch',
      url: 'https://safelaunch.io/',
      order: 32,
    },
    {
      srcGray: partner33Img.src,
      srcColor: partner33Img.src,
      name: 'Script Network',
      url: 'https://t.co/WfXwas9L7B',
      order: 33,
    },
    {
      srcGray: partner34Img.src,
      srcColor: partner34Img.src,
      name: 'Source Hat',
      url: 'https://sourcehat.com/',
      order: 34,
    },
    {
      srcGray: partner35Img.src,
      srcColor: partner35Img.src,
      name: 'Spintop',
      url: 'https://spintop.network/',
      order: 35,
    },
    {
      srcGray: partner36Img.src,
      srcColor: partner36Img.src,
      name: 'Synapse Network',
      url: 'https://synapse.network/',
      order: 36,
    },
    {
      srcGray: partner37Img.src,
      srcColor: partner37Img.src,
      name: 'TGDAO',
      url: 'https://tgdao.io/',
      order: 37,
    },
    {
      srcGray: partner38Img.src,
      srcColor: partner38Img.src,
      name: 'Chappy',
      url: 'https://chappyz.com/',
      order: 38,
    },
    {
      srcGray: partner39Img.src,
      srcColor: partner39Img.src,
      name: 'UnReal Capital',
      url: 'https://sc.linkedin.com/company/unreal-capital-vc',
      order: 39,
    },
    {
      srcGray: partner40Img.src,
      srcColor: partner40Img.src,
      name: 'Yasar Corporation',
      url: 'https://www.yasarcorp.com/',
      order: 40,
    },
    {
      srcGray: partner41Img.src,
      srcColor: partner41Img.src,
      name: 'ZBS Capital',
      url: 'https://www.zbs.capital/',
      order: 41,
    },
    {
      srcGray: partner42Img.src,
      srcColor: partner42Img.src,
      name: 'Zephyrus Capital',
      url: 'https://zephyruscapital.com/',
      order: 42,
    },
    {
      srcGray: partner43Img.src,
      srcColor: partner43Img.src,
      name: 'DeChat',
      url: 'https://www.dechat.io/',
      order: 43,
    },
    {
      srcGray: partner44Img.src,
      srcColor: partner44Img.src,
      name: 'UniLend',
      url: 'https://unilend.finance/',
      order: 44,
    },
    {
      srcGray: partner45Img.src,
      srcColor: partner45Img.src,
      name: 'BRCStarter',
      url: 'https://t.co/XuuNWa6xDz',
      order: 45,
    },
    {
      srcGray: partner46Img.src,
      srcColor: partner46Img.src,
      name: 'Kap Games',
      url: 'https://www.kap.gg/games/sugar-kingdom',
      order: 46,
    },
    {
      srcGray: partner47Img.src,
      srcColor: partner47Img.src,
      name: 'Bitcoin Cats',
      url: 'https://www.bitcoincats.world/',
      order: 47,
    },
    {
      srcGray: partner48Img.src,
      srcColor: partner48Img.src,
      name: 'Orange',
      url: 'https://www.orangecrypto.com/',
      order: 48,
    },
    {
      srcGray: partner49Img.src,
      srcColor: partner49Img.src,
      name: 'BitX',
      url: 'https://docs.bxdx.io/socials/media-kit',
      order: 49,
    },
    {
      srcGray: partner50Img.src,
      srcColor: partner50Img.src,
      name: 'Gowrap',
      url: 'https://gowrap.xyz/',
      order: 50,
    },
    {
      srcGray: partner51Img.src,
      srcColor: partner51Img.src,
      name: 'OrdifyWorld',
      url: 'https://ordify.world/',
      order: 51,
    },
    {
      srcGray: partner52Img.src,
      srcColor: partner52Img.src,
      name: 'Gelios',
      url: 'https://dapp.gelios.io/entry',
      order: 52,
    },
    {
      srcGray: partner53Img.src,
      srcColor: partner53Img.src,
      name: 'Hotfi',
      url: 'https://twitter.com/HOTFI_',
      order: 53,
    },
    {
      srcGray: partner54Img.src,
      srcColor: partner54Img.src,
      name: 'DexOrdi',
      url: 'https://www.dexordi.com/',
      order: 54,
    },
    {
      srcGray: partner55Img.src,
      srcColor: partner55Img.src,
      name: 'Mail Zero',
      url: 'https://mailzero.network/',
      order: 55,
    },
    {
      srcGray: partner56Img.src,
      srcColor: partner56Img.src,
      name: 'Corridor Finance',
      url: 'https://corridor.finance/',
      order: 56,
    },
    {
      srcGray: partner57Img.src,
      srcColor: partner57Img.src,
      name: 'Orange DX',
      url: 'https://drive.google.com/drive/folders/1-I4nT9--4zudJL3P_R0q2603PyG5S_Lp',
      order: 57,
    },
    {
      srcGray: partner58Img.src,
      srcColor: partner58Img.src,
      name: 'Sky Verse',
      url: 'https://side.xyz/skyverse',
      order: 58,
    },
    {
      srcGray: partner59Img.src,
      srcColor: partner59Img.src,
      name: 'Bear Brc20',
      url: 'https://bearbrc20.com/',
      order: 59,
    },
    {
      srcGray: partner60Img.src,
      srcColor: partner60Img.src,
      name: 'TrendX',
      url: 'https://app.trendx.tech/',
      order: 60,
    },
    {
      srcGray: partner61Img.src,
      srcColor: partner61Img.src,
      name: 'Red Kite',
      url: 'https://twitter.com/redkitepad',
      order: 61,
    },
    {
      srcGray: partner62Img.src,
      srcColor: partner62Img.src,
      name: 'Spores Network',
      url: 'https://spores.app/',
      order: 62,
    },
    {
      srcGray: partner63Img.src,
      srcColor: partner63Img.src,
      name: 'Genesis capital',
      url: 'https://twitter.com/genesiscapital_',
      order: 63,
    },
    {
      srcGray: partner64Img.src,
      srcColor: partner64Img.src,
      name: 'Hape',
      url: 'https://twitter.com/HAPEsocial',
      order: 64,
    },
    {
      srcGray: partner65Img.src,
      srcColor: partner65Img.src,
      name: 'zkbase',
      url: 'https://zkbase.org/',
      order: 65,
    },
    {
      srcGray: partner66Img.src,
      srcColor: partner66Img.src,
      name: 'Ordbridge',
      url: 'https://www.ordbridge.io/',
      order: 66,
    },
    {
      srcGray: partner67Img.src,
      srcColor: partner67Img.src,
      name: 'Thena',
      url: 'https://thena.fi/',
      order: 67,
    },
    {
      srcGray: partner68Img.src,
      srcColor: partner68Img.src,
      name: 'WeLabs',
      url: 'https://twitter.com/welabs_vc',
      order: 68,
    },
    {
      srcGray: partner69Img.src,
      srcColor: partner69Img.src,
      name: 'Unicorn Ventures',
      url: 'https://unicornventures.co.in/',
      order: 69,
    },
    {
      srcGray: partner70Img.src,
      srcColor: partner70Img.src,
      name: 'Good Games Guild',
      url: 'https://goodgamesguild.com/',
      order: 70,
    },
    {
      srcGray: partner71Img.src,
      srcColor: partner71Img.src,
      name: 'Peech Capital',
      url: 'https://peech.capital/',
      order: 71,
    },
    {
      srcGray: partner72Img.src,
      srcColor: partner72Img.src,
      name: 'Kairon Labs',
      url: 'https://kaironlabs.com/',
      order: 72,
    },
    {
      srcGray: partner73Img.src,
      srcColor: partner73Img.src,
      name: 'BRC20 Dex',
      url: 'https://brc-20dex.io/',
      order: 73,
    },
    {
      srcGray: partner74Img.src,
      srcColor: partner74Img.src,
      name: 'Kanga',
      url: 'https://kanga.exchange/',
      order: 74,
    },
    {
      srcGray: partner75Img.src,
      srcColor: partner75Img.src,
      name: 'Plena Finance',
      url: 'https://plena.finance/',
      order: 75,
    },
    {
      srcGray: partner76Img.src,
      srcColor: partner76Img.src,
      name: 'Octavia',
      url: 'https://octavia.one/',
      order: 76,
    },
    {
      srcGray: partner77Img.src,
      srcColor: partner77Img.src,
      name: 'BTCS',
      url: 'https://www.btcsdao.org/en',
      order: 77,
    },
    // {
    //   srcGray: partner78Img.src,
    //   srcColor: partner78Img.src,
    //   name: 'Enjin  Starter',
    //   url: 'https://enjinstarter.com/',
    //   order: 78,
    // },
    {
      srcGray: partner79Img.src,
      srcColor: partner79Img.src,
      name: 'Marshland Capital',
      url: 'https://www.marshlandcapital.com/',
      order: 79,
    },
    {
      srcGray: partner80Img.src,
      srcColor: partner80Img.src,
      name: 'Nx Gen',
      url: 'https://www.nxgen.xyz/',
      order: 80,
    },
    {
      srcGray: partner81Img.src,
      srcColor: partner81Img.src,
      name: 'IBC Group',
      url: 'https://x.com/ibcgroupio?s=20',
      order: 81,
    },
    {
      srcGray: partner82Img.src,
      srcColor: partner82Img.src,
      name: 'AlexLab',
      url: 'https://alexgo.io/',
      order: 82,
    },
  ],
};
