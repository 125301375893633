/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { headerImages } from '../../../../utils/headerData';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: '#68030F',
  },
  background: {
    margin: '0 auto',
    backgroundImage: `url(${headerImages.backgroundImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom center',
    height: '100vh',
    zIndex: -2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    justifyContent: 'center',
  },
  logo: {
    width: '40%',
    maxWidth: '500px',
    minWidth: '400px',
    height: 'auto',
    zIndex: 2,
    position: 'absolute',
    top: 'calc(80px + 5vh)',
  },
  characters: {
    width: '50%',
    maxWidth: '600px',
    minWidth: '500px',
    height: 'auto',
    zIndex: 1,
    position: 'absolute',
    bottom: '6vh',
  },
}));

const LandingPageHeader = () => {
  const classes = useStyles();
  const [hover, setHover] = useState<boolean>(false);

  return (
    <div className={classes.root} id='header'>
      <Grid className={`${classes.background}`}>
        <div className={`${classes.container}`}>
          <img className={classes.logo} src={headerImages.LogoBgImg} alt='logo' />
          <img
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(false)}
            className={classes.characters}
            src={hover ? headerImages.GroupHoverImg : headerImages.GroupImg}
            alt='characters'
          />
        </div>
      </Grid>
    </div>
  );
};

export default LandingPageHeader;
