/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import { makeStyles, Theme, Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import background from '../../../../assets/images/partners/bg.svg';
import label from '../../../../assets/images/partners/label.svg';
import { useScroll } from '../../../../hooks/useScroll';
import { sectionsPartners } from '../../../../utils/sectionPartnersNewData';

const labelImg = new Image();
labelImg.src = label;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: 'white',
  },
  mainContainer: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    position: 'relative',
    display: 'flex',
    justifySelf: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '30px',
    width: '100%',
    height: '100%',
    paddingTop: '120px',
    zIndex: 1,
    [theme.breakpoints.up(900)]: {
      paddingTop: '120px',
    },
    [theme.breakpoints.up(1300)]: {
      paddingTop: '150px',
    },
  },
  label: {
    display: 'flex',
    indexZ: 1,
    animation: 'slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
    [theme.breakpoints.down(700)]: {
      width: '250px',
    },
    [theme.breakpoints.up(700)]: {
      width: '300px',
    },
    [theme.breakpoints.up(900)]: {
      width: '320px',
    },
    [theme.breakpoints.up(1100)]: {
      width: '350px',
    },
    [theme.breakpoints.up(1550)]: {
      width: '500px',
    },
  },
  logosContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    padding: '0 6%',
    animation: 'fadeoutfadeinTeam 1s linear',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    maxHeight: '105px',
    [theme.breakpoints.down(700)]: {
      width: '70px',
      maxHeight: '70px',
    },
    [theme.breakpoints.up(700)]: {
      width: '70px',
      maxHeight: '70px',
    },
    [theme.breakpoints.up(800)]: {
      width: '75px',
      maxHeight: '75px',
    },
    [theme.breakpoints.up(900)]: {
      width: '80px',
    },
    [theme.breakpoints.up(1000)]: {
      width: '90px',
    },
    [theme.breakpoints.up(1300)]: {
      width: '95px',
    },
    [theme.breakpoints.up(1550)]: {
      width: '110px',
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
    backgroundColor: '#68030F10',
    borderRadius: '12px',
    padding: '1px',
    boxSizing: 'border-box',
    transition: 'transform 0.3s',
    maxHeight: '60px',
    [theme.breakpoints.between(700, 0)]: {
      maxHeight: '50px',
    },
    [theme.breakpoints.up(700)]: {
      maxHeight: '56px',
    },
    [theme.breakpoints.up(800)]: {
      maxHeight: '60px',
    },
    [theme.breakpoints.up(900)]: {
      maxHeight: '66px',
    },
    [theme.breakpoints.up(1000)]: {
      maxHeight: '72px',
    },
    [theme.breakpoints.up(1300)]: {
      maxHeight: '75px',
    },
    [theme.breakpoints.up(1550)]: {
      maxHeight: '85px',
    },
  },
  item: {
    minHeight: '10px',
    margin: '4px',
  },
  logoHover: {
    cursor: 'pointer',
    transform: 'scale(1.2)',
    backgroundColor: '#68030F30',
    borderRadius: '4px',
  },
  animationContainer: {
    animation: 'fadeoutfadeinTeam 1s linear',
  },
}));

const LandingPagePartners = () => {
  const classes = useStyles();
  const refSection = useRef(null);
  const { scrollPosition } = useScroll();
  const [classNameSet, setClassName] = useState('');
  const [isHoverImage, setIsHoverImage] = useState(-1);
  const [hoverOn, setHoverOn] = useState('');

  useEffect(() => {
    setClassName(classes.animationContainer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classNameSet]);

  const handleClickLogo = (url: string) => {
    window.open(url, '_blank', 'noopener');
  };

  return (
    <div className={classes.root} id='partners'>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='space-between'
        className={classes.mainContainer}
        ref={refSection}>
        {scrollPosition === 4 && (
          <Grid className={classes.gridContainer}>
            <img src={labelImg.src} alt='partners' className={`${classes.label}`} />
            <Grid container className={`${classes.logosContainer} ${classNameSet}`}>
              {sectionsPartners.logos.map((logo, i) => (
                <Grid key={`${logo.url}`} className={`${classNameSet} ${classes.item}`}>
                  <Grid className={classes.cardContainer}>
                    <img
                      aria-hidden='true'
                      src={hoverOn === `logo${i}` ? logo.srcColor : logo.srcGray}
                      alt='imageteam'
                      className={isHoverImage === i ? `${classes.logoImage} ${classes.logoHover}` : classes.logoImage}
                      onClick={() => (logo.url ? handleClickLogo(logo.url) : null)}
                      onMouseEnter={() => {
                        setHoverOn(`logo${i}`);
                        setIsHoverImage(i);
                      }}
                      onMouseLeave={() => {
                        setHoverOn('');
                        setIsHoverImage(-1);
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default LandingPagePartners;
