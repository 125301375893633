/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { makeStyles, Theme, Grid } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { sectionDocs } from '../../../../utils/sectionDocsData';
import { useScroll } from '../../../../hooks/useScroll';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: 'white',
  },
  mainContainer: {
    backgroundImage: `url(${sectionDocs.pageInfo.bg})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    position: 'relative',
    display: 'flex',
    justifySelf: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '40px',
    width: '100%',
    height: '100%',
    paddingTop: '100px',
    zIndex: 1,
    [theme.breakpoints.down(900)]: {
      flexDirection: 'column',
      gap: '20px',
    },
  },
  token: {
    width: '350px',
    display: 'block',
    indexZ: 2,
    cursor: 'pointer',
    animation: 'fadeInLeft 1s ease-out',
    [theme.breakpoints.down(900)]: {
      width: '200px',
    },
  },
  whitePaper: {
    width: '350px',
    display: 'block',
    indexZ: 2,
    cursor: 'pointer',
    animation: 'fadeInRight 1s ease-out',
    [theme.breakpoints.down(900)]: {
      width: '200px',
    },
  },
  label: {
    width: '450px',
    display: 'block',
    position: 'absolute',
    top: '18.5vh',
    margin: 'auto',
    indexZ: 10,
    animation: 'fadeInDown 1s ease-out',
    [theme.breakpoints.down(900)]: {
      width: '300px',
    },
  },
}));

const LandingPageDocs = () => {
  const classes = useStyles();
  const refSection = useRef(null);
  const [tokenHover, setTokenHover] = useState<boolean>(false);
  const [whiteHover, setWhiteHover] = useState<boolean>(false);
  const { scrollPosition } = useScroll();

  const handleButton = (link: string) => {
    window.open(link, '_blank', 'noopener');
  };

  return (
    <div className={classes.root} id='docs'>
      <Grid className={classes.mainContainer} ref={refSection}>
        {scrollPosition === 5 && (
          <Grid className={classes.gridContainer}>
            <img
              onMouseEnter={() => setTokenHover(true)}
              onMouseLeave={() => setTokenHover(false)}
              onPointerEnter={() => setTokenHover(true)}
              onPointerLeave={() => setTokenHover(false)}
              src={tokenHover ? sectionDocs.cards[0].image : sectionDocs.cards[0].hover}
              alt='token'
              className={`${classes.token}`}
              onClick={() => handleButton(sectionDocs.cards[0].url)}
            />
            <img
              onMouseEnter={() => setWhiteHover(true)}
              onMouseLeave={() => setWhiteHover(false)}
              onPointerEnter={() => setWhiteHover(true)}
              onPointerLeave={() => setWhiteHover(false)}
              src={whiteHover ? sectionDocs.cards[1].image : sectionDocs.cards[1].hover}
              alt='whitePaper'
              className={`${classes.whitePaper}`}
              onClick={() => handleButton(sectionDocs.cards[1].url)}
            />
            <img src={sectionDocs.pageInfo.titleImg} alt='game view' className={`${classes.label}`} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default LandingPageDocs;
