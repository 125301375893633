/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import background from '../../../../assets/images/teamAdv/bgMobile.svg';
import char1 from '../../../../assets/images/teamAdv/char1.svg';
import char2 from '../../../../assets/images/teamAdv/char2.svg';
import label from '../../../../assets/images/teamAdv/labelMobile.svg';
import logoEnabled from '../../../../assets/images/teamAdv/logoEnabled.svg';
import logoDisabled from '../../../../assets/images/teamAdv/logoDisabled.svg';

const char1Img = new Image();
char1Img.src = char1;

const char2Img = new Image();
char2Img.src = char2;

const labelImg = new Image();
labelImg.src = label;

const logoEnabledImg = new Image();
logoEnabledImg.src = logoEnabled;

const logoDisabledImg = new Image();
logoDisabledImg.src = logoDisabled;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: '#68030F',
  },
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    height: '100vh',
    width: '100VW',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingTop: '12vh',
    position: 'relative',
  },
  label: {
    height: '10vh',
    display: 'flex',
    indexZ: 10,
    animation: 'fadeInDown 1s ease-out',
  },
  logoCorp: {
    marginTop: '5vh',
    display: 'block',
    width: '70vw',
  },
  imageChar1: {
    height: '16vh',
    display: 'block',
    position: 'absolute',
    bottom: '13vh',
    left: '2vw',
    indexZ: 10,
    animation: 'fadeInLeft 1s ease-out',
  },
  imageChar2: {
    height: '16vh',
    display: 'block',
    position: 'absolute',
    bottom: '13vh',
    right: '5vw',
    indexZ: 10,
    animation: 'fadeInRight 1s ease-out',
  },
}));

const LandingPageTeamAndADVMobile = () => {
  const classes = useStyles();

  const handleButton = (link: string) => {
    window.open(link, '_blank', 'noopener');
  };

  return (
    <>
      <div className={classes.root} id='team'>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          className={`${classes.background} ${classes.contentContainer}`}>
          <Grid container direction='column' alignItems='center' justifyContent='center'>
            <img aria-hidden='true' src={labelImg.src} alt='imageheader' className={classes.label} />
            <img
              className={classes.logoCorp}
              src={logoDisabledImg.src}
              alt='logo'
              onClick={() => handleButton('https://apesgroup.xyz/')}
            />
            <img src={char1Img.src} alt='imageChar1' className={`${classes.imageChar1}`} />
            <img src={char2Img.src} alt='imageChar2' className={`${classes.imageChar2}`} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LandingPageTeamAndADVMobile;
