import bg from '../assets/images/documents/bg.svg';
import bgMobile from '../assets/images/documents/bgMobile.svg';
import header from '../assets/images/documents/label.svg';
import headerMobile from '../assets/images/documents/labelMobile.svg';
import box1 from '../assets/images/documents/tokenInctive.svg';
import box1Hover from '../assets/images/documents/tokenActive.svg';
import box2 from '../assets/images/documents/whiteInactive.svg';
import box2Hover from '../assets/images/documents/whiteActive.svg';

const headerImg = new Image();
headerImg.src = header;
const headerMobileImg = new Image();
headerMobileImg.src = headerMobile;

const bgImg = new Image();
bgImg.src = bg;
const bgMobileImg = new Image();
bgMobileImg.src = bgMobile;

const box1Img = new Image();
box1Img.src = box1;
const box2Img = new Image();
box2Img.src = box2;

const box1HoverImg = new Image();
box1HoverImg.src = box1Hover;
const box2HoverImg = new Image();
box2HoverImg.src = box2Hover;

export const sectionDocs = {
  pageInfo: {
    title: 'Documents',
    titleImg: headerImg.src,
    titleMobileImg: headerMobileImg.src,
    bg: bgImg.src,
    bgMobile: bgMobileImg.src,
  },
  cards: [
    {
      name: 'tokenomics',
      image: box1Img.src,
      hover: box1HoverImg.src,
      url: 'https://docs.google.com/spreadsheets/d/1e64WoDQafqze_xm0Ux-qqmWaJUutVkyN/edit?usp=sharing&ouid=101584096667939917170&rtpof=true&sd=true',
    },
    {
      name: 'whitepaper',
      image: box2Img.src,
      hover: box2HoverImg.src,
      url: 'https://docs.sugarkingdom.io/overview/about-sugar-kingdom-odyssey',
    },
  ],
};
