import { AppBar, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import { menuImages } from '../../../../utils/sectionMenu';
import MenuDisplay from '../MenuMobile';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    width: '100%',
    zIndex: 1900,
    left: '0px',
  },
  spanPlayNowContainer: {
    position: 'fixed',
    top: '0px',
    width: '100%',
  },
  logoOneMin: {
    width: '180px',
    position: 'relative',
    left: '20px',
    top: '10px',
  },
  toggleButton: {
    width: '40px',
    position: 'relative',
    right: '20px',
    top: '15px',
    zIndex: 40,
  },
  containerMenuMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundImage: `url(${menuImages.menuBackgroundImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    height: '70px',
    backgroundColor: 'transparent !important',
  },
  appBarMini: {
    backgroundImage: `url(${menuImages.miniBackgroundMobileImg})`,
    backgroundSize: 'cover',
    height: '55px',
    backgroundColor: 'transparent',
    marginTop: '-4px',
    backgroundPosition: 'top',
    zIndex: 10,
  },
}));
interface props {
  handlePageChange: any;
  currentPage: number;
  handleExpandedMenu: any;
  expandedMenu: boolean;
  handleMiniMenu?: boolean;
}
const LandingPageMenuMobile = ({
  handlePageChange,
  currentPage,
  handleMiniMenu,
  handleExpandedMenu,
  expandedMenu,
}: props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);

  function openMenu() {
    setMenuOpen(!menuOpen);
  }

  return (
    <div className={classes.root}>
      <MenuDisplay
        menuType='landpage-consumer'
        onClose={openMenu}
        open={menuOpen}
        handlePageChange={handlePageChange}
        currentPage={currentPage}></MenuDisplay>
      {/* {handleMiniMenu ? (
        <AppBar position='static' className={classes.appBarMini} />
      ) : ( */}
      <AppBar>
        <div className={classes.containerMenuMobile}>
          <span onClick={() => handlePageChange(0)} onFocus={() => void 0} aria-hidden='true'>
            <img className={classes.logoOneMin} src={menuImages.logoImg} alt='Logo' />
          </span>
          <span onClick={openMenu} aria-hidden='true'>
            <img
              className={classes.toggleButton}
              src={menuOpen ? menuImages.menuButtonHoverImg : menuImages.menuButtonImg}
              alt='Menu Button'
            />
          </span>
        </div>
      </AppBar>
      {/* )} */}
    </div>
  );
};

export default LandingPageMenuMobile;
