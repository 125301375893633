/* eslint-disable jsx-a11y/no-static-element-interactions */
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { headerImages } from '../../../../utils/headerData';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
  },
  background: {
    backgroundImage: `url(${headerImages.backgroundMobileImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100vh',
    width: '100vw',
    zIndex: -2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '90%',
    height: 'auto',
  },
  logo2: {
    width: '90%',
    height: 'auto',
  },
}));

const LandingPageHeaderMobile = () => {
  const classes = useStyles();
  const [hover, setHover] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setHover(true);
    }, 1000);
    if (hover) {
      setTimeout(() => {
        setHover(false);
      }, 1000);
    }
  }, [hover]);

  return (
    <div className={classes.root} id='header'>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onPointerEnter={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
        className={`${classes.background}`}>
        <img className={classes.logo} src={headerImages.LogoBgImg} alt='logo' />
        {/* <img
            className={classes.logo2}
            src={hover ? headerImages.GroupHoverImg : headerImages.GroupImg}
            alt='characters'
          /> */}
      </div>
    </div>
  );
};

export default LandingPageHeaderMobile;
